/* Layouts */


// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 1090px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .navbar-brand-inner-wrapper {
          margin-right: auto;
          .brand-logo {
            display: none;
          }
          .brand-logo-mini {
            display: none;
          }
        }  
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          .nav-link {
            display: block;
            padding-left: .5rem;
            padding-right: .5rem;            
            text-align: center;
            position: static;
            .menu-title,
            .badge,.menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);                
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title {
                @include border-radius(0 5px 0 0px);
                top: .5rem;
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);                  
                }
              }
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $icon-only-menu-bg-light;
                padding: 0.95rem .3rem 0 .95rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1rem;
                box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.45);              
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                  box-shadow: -4px 0px 7px 0px rgba(182, 185, 189, 0.45);                                
                }
                &:after {
                  display: none;
                }
              }
              &:hover {
                .menu-title {
                  background: $icon-only-menu-bg-light;
                  line-height: 1rem;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: .5rem 0;
              background: $icon-only-menu-bg-light;
              @include border-radius(0 0 5px 0);
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              box-shadow: 4px 4px 7px 0px rgba(182, 185, 189, 0.45);
              @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right:$sidebar-width-icon;
                  @include border-radius(0 0 0 5px);
                  box-shadow: -4px 4px 7px 0px rgba(182, 185, 189, 0.45);
              }
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
            }
          }
        }
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-icon});
    }
  }
}

.elevation {
  box-shadow: 0 2px 16px $gray-400;
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;

  .sidebar {
    .nav {
      padding-right: 0;
      &.sub-menu {
        padding: $rtl-sidebar-submenu-padding;
        @at-root #{selector-append(".sidebar-icon-only", &)} {
          padding-right: 0rem;
          .nav-item {
            .nav-link {
              padding-right: 3rem;
              text-align: right;
              &:before {
                right: 1.75rem;
              }
            }
          }
        }
      }
    }
  }

  .product-chart-wrapper,
  .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}
