
.settings-panel {
  display: block;
  position: fixed;
  top: 0;
  right: -$settings-panel-width;
  bottom: 0;
  width: $settings-panel-width;
  height: 100vh;
  min-height: 100%;
  background: $card-bg;
  padding-top: 50px;
  transition-duration: $action-transition-duration;
  transition-timing-function: $action-transition-timing-function;
  transition-property: right, box-shadow;
  z-index: 9000;

  .nav-tabs {
    display: flex;
    justify-content: center;
    width: auto;
    margin: 0;
    padding: 0;
    background: $darkslategray;

    .nav-item {
      border: none;

      .nav-link {
        text-align: center;
        border: none;
        display: flex;
        align-items: center;
        color: rgba($dark, 0.5);
        transition-duration: 0.4s;
        transition-property: color;
        justify-content: center;

        &.active {
          background: transparent;
          color: $dark;
        }
      }
    }
  }

  .tab-content {
    border: none;
    padding: 20px 0 0;

    .tab-pane {
      &.scroll-wrapper {
        position: relative;
        max-height: 100vh;
        height: 100%;
        padding-bottom: 150px;
      }
    }
  }

  .settings-heading {
    padding: 16px 0 13px 20px;

    .rtl & {
      padding: 16px 35px 13px 0;
      text-align: right;
    }
  }

  small.settings-heading {
    .rtl & {
      padding: 16px 0 13px 12px;
    }
  }

  .sidebar-bg-options {
    padding: 13px 35px;
    display: flex;
    align-items: center;
    font-size: $default-font-size;
    line-height: 1;
    color: lighten($black, 35%);
    background: $white;
    transition-duration: $action-transition-duration;
    transition-property: background;

    &.selected {
      background: color(gray-lightest);
    }

    .rtl & {
      .rounded-circle {
        @extend .mr-0;
        @extend .ml-3;
      }
    }
  }

  .color-tiles {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 35px 10px;
    padding-top: 15px;

    .tiles {
      @extend .img-ss;
      @extend .rounded-circle;
      margin: 10px 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: "";
        width: 0;
        height: 0;
        opacity: 0;
        background: rgba($white, 0.5);
        border-radius: 100%;
        border: 0 solid rgba($white, 0.4);
        transition-duration: $action-transition-duration;
        transition-timing-function: $action-transition-timing-function;
      }

      &.selected {
        &:before {
          width: 10px;
          height: 10px;
          opacity: 1;
          border-width: $border-width;
        }
      }

      &.primary {
        @extend .bg-primary;
      }

      &.success {
        @extend .bg-success;
      }

      &.warning {
        @extend .bg-warning;
      }

      &.danger {
        @extend .bg-danger;
      }

      &.pink {
        background: color(pink);
      }

      &.info {
        @extend .bg-info;
      }

      &.dark {
        @extend .bg-dark;
      }

      &.default {
        border: 1px solid lighten(color(gray), 60%);
        background: $darkslategray;
      }
    }
  }


  &.open {
    right: 0;
    box-shadow: 7px 0 80px -9px rgba(0, 0, 0, 0.2);
  }

  .settings-close {
    position: absolute;
    top: 15px;
    right: 10px;
    color: theme-color(primary);
    background: transparent;
    border-radius: 4px;
    padding: 0 3px;
    cursor: pointer;
    transition-duration: 0.2s;
    z-index: 999;

    &:hover {
      background: rgba($white, 0.3);
    }

    .rtl & {
      right: unset;
      left: 10px;
    }
  }

  .events {
    p {
      // font-family: $type-1;
    }

    .rtl & {
      p {
        text-align: right;
      }

      i {
        @extend .mr-0;
        @extend .ml-2;
      }
    }
  }


  .demo-screen-wrapper {
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 100px;
    padding-top: 20px;

    .demo-thumb-image {
      display: block;
      margin-bottom: 5px;
      padding: 10px 25px;

      img {
        width: 100%;
        max-width: 100%;
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
        transform: scale(1);
        border-radius: 4px;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        transition-property: "transform", "box-shadow";

        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}


.right-sidebar-toggler-wrapper {
  position: fixed;
  bottom: 50px;
  right: 12px;
  z-index: 50;
  padding: 5px;
  background: color(gray-lightest);
  border-radius: 4px;
  box-shadow: 0px 10px 50px -4px rgba(0, 0, 0, 0.5);

  .sidebar-toggler {
    padding: 7px 10px;
    color: $body-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    background: $card-bg;
    margin-bottom: 5px;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: rgba(theme-color(primary), .9);
      color: theme-color(light);
      cursor: pointer;
    }

    &#layout-toggler {
      background: rgba(theme-color(primary), .9);
      color: theme-color(light);
      i {
        //-webkit-animation:spin 4s linear infinite;
        //-moz-animation:spin 4s linear infinite;
        //animation:spin 4s linear infinite;
        //animation: vibration 1.9s linear infinite;
        display: block;
      }
      &:hover {
        background: rgba(theme-color(primary), .5);
      }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    @keyframes vibration {
      50% { transform: skewY(1deg) skewX(-1deg) scale(1.8) }
    }

    }

    a {
      color: inherit;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}