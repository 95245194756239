/* Cards */

.card {
  box-shadow: $card-box-shadow;
  -webkit-box-shadow: $card-box-shadow;
  -moz-box-shadow: $card-box-shadow;
  -ms-box-shadow: $card-box-shadow;
  border-radius: .22rem .22rem;
  .card-body {
    padding: $card-padding-y $card-padding-x;
    + .card-body {
      padding-top: 1rem;
    }
  }
  .card-image {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    min-height: 150px;
    max-height: 450px;
    padding: .5rem .5rem;
    img {
      display: block;
      width: 100%;
      max-width: 450px;
    }
  }
  .topcard {
    box-shadow: 0px 3px 12px 3px rgba(139, 145, 147, 0.15);
  }
  .card-title {
    color: $card-title-color;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: bold; 
  }
  .card-subtitle {
    @extend .text-gray;
    font-weight: 400;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  
  }
  .card-description {
    position: relative;
    margin: .5rem 0 .7rem 0;
    display: block;
    font-weight: 400;
    color: $card-description-color;
    & p{
      line-height: 1rem;
    }
  }
  &.card-outline-success {
		border: 1px solid theme-color("success");
	}
	&.card-outline-primary {
		border: 1px solid theme-color("primary");
	}
	&.card-outline-warning {
		border: 1px solid theme-color("warning");
	}
	&.card-outline-danger {
		border: 1px solid theme-color("danger");
	}
	&.card-rounded {
		@include border-radius(5px);
	}

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
    font-size: .7rem;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top:0;
      right: 0;
      height: 100%;
    }
  }
  &.card-weather {
    .weather-daily {
      .weather-day {
        opacity: .5;
        font-weight: 900;
      }
      i {
        font-size: 20px;
      }
      .weather-temp {
        margin-top: .5rem;        
        margin-bottom: 0;
        opacity: .5;
        font-size: .75rem;
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
  }
}
.person {
  padding: .35rem .1rem;
}
.person25 {
  position: relative;
  width: 165px;
  height: 220px;
  overflow: hidden;
  object-fit:cover;
  img {
    width: 165px;
    height: 220px;
    transition: transform .5s;
    &:hover {
      transform: scale(1.05);
      z-index: 10;
    }
  }
  .desc {
    position: absolute;
    font-size: 1rem;
    line-height: 1rem;
    top: 85px;
    left: .25rem;
    color: $white;
    text-shadow: 0 .1rem .2rem rgba(7, 7, 7, 0.9);
  }
  .place {
    position: absolute;
    font-size: .8rem;
    line-height: 1rem;
    top: 105px;
    left: .25rem;
    color: color('gray-lighter');
    background: theme-color('light-dark');
    padding: 0 .25rem;
    border-radius: .1rem;
  }
}

#map {
  width: 100%;
  min-height: 520px;
  padding: 0;
  margin: 0;
}

.icon-link {
  color: color(gray-light);
  opacity: .75;
  &:hover {
    color: color(gray-light);
    opacity: 1;
  }
}

.to-new-link {
  position: absolute;
  right: 1rem;
  top: 1.3rem;
  color: color(gray-light);
  opacity: .75;
  &:hover {
    color: color(gray-light);
    opacity: 1;
  }
  i {
    position: relative;
  }
}

.news-card {
  width: 100%;
  padding: .2rem .3rem;
  .news-header {
    font-size: 1.5rem;
    padding: .5rem 0;
    color: color("gray");
    & h5{
      font-size: 1.15rem;
      font-weight: 300;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  img {
    border: 1px solid color("gray-lightest");
    border-radius: .3rem .3rem;
    display: block;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
    &:hover{
      opacity: .75;
      box-shadow: 0 1px 10px color("gray-lighter");
    }
  }
  p {
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: 300;
    color: ("gray-dark");
  }
  .news-date {
    margin: 1rem 0;
    & i{
      display: inline-block;
      padding: 0 .2rem;
      color: color("gray");
    }
    & span{
      display: inline-block;
      font-weight: 300;
      color: color("gray");
      font-size: 85%;
    }
  }
  .news-index-date {
    margin: 0 0 .5rem 0;
    & i{
      display: inline-block;
      padding: 0 .2rem;
      color: color("gray");
    }
    & span{
      display: inline-block;
      font-weight: 400;
      color: color("gray");
      font-size: 90%;
    }
  }
}

.disabled-card {
  opacity: .5;
}

.last-news-card {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  min-height: 150px;
  max-height: 350px;
  padding: .5rem .5rem;
  &:hover {
    text-decoration: none;
  }
  h6 {
  padding: 1rem 0 0 0;
  color: color("gray");
  font-size: 1.15rem;
  font-weight: 300;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  img {
    position: relative;
    display: block;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
    margin: .5rem;
    border: 1px solid color("gray-lightest");
    border-radius: .3rem .3rem;
  }
  .news-date {
    margin: 1rem 0;
    & i{
      display: inline-block;
      padding: 0 .2rem;
      color: color("gray");
    }
    & span{
      display: inline-block;
      font-weight: 300;
      color: color("gray");
      font-size: 85%;
    }
  }
  p {
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 300;
    color: ("gray-dark");
  }
}

.new {
  a {
    font-weight: bold;
    color: theme-color(primary);
    padding: 0 0.25rem;
    background: color(gray-lightest);
  }
}

.accent-image {
  border: 1px solid color("gray-lightest");
  border-radius: .3rem .3rem;
  box-shadow: 0 1px 10px color("gray-lighter");
}

.movie-description {
  font-size: .76rem;
  color: theme-color(light-dark);
  line-height: .9rem;
  margin: .3rem .2rem;
}

.publish {
  position: relative;
  z-index: 100;
  min-width: 200px;
  min-height: 290px;
  padding-bottom: 5rem;
  display: inline-flex;
  &:after{
    position: absolute;
    content: "";
    width: 200px;
    height: 16px;
    border-radius: 50%;
    bottom: 10px;
    box-shadow: 0 22px 20px -6px #555454;
    z-index: -1;
  }
  .box {
    position: absolute;
    margin: 0 auto;
    width: 200px;
    border-top: solid #dee6e8 6px;
    border-left: solid #595f63 6px;
    -webkit-transition: all ease 1s;
    &:before {
      content: '';
      position: absolute;
      left: -12px;
      bottom: -12px;
      border: solid transparent 12px;
      border-left-color: #fff;
    }
    &:after {
      content: '';
      position: absolute;
      top: -24px;
      right: 0;
      border: solid transparent 12px;
      border-right-color: #fff;
    }
    & img {
      width: 100%;
    }
  }
}
.pub-control-group {
  margin: 1rem auto .5rem auto;
  border: 3px solid #fff;
  border-radius: .3rem;
  box-shadow: 0 1px 5px #bfbfbf;
}

.products {
  a {
    &:hover {
      border: 1px solid color(gray-light);
      text-decoration: none;
      p {
        color: theme-color(dark);
      }
      img {
        border-radius: .18rem .18rem;
        box-shadow: 0px 1px 12px 3px rgba(187, 192, 194, 0.2);
        //filter: grayscale(.5) opacity(.8);
      }
    }
  }
  .node {
    position: relative;
    p {
      margin: .5rem .3rem;
      font-size: .8rem;
      line-height: .9rem;
    }
    h5 {
      color: $darkslategray;
      font-size: .85rem;
      margin: .3rem .6rem 0 .6rem;
      span {
        font-size: .735rem;
        color: #7f848b;
        &:after {
          content: ' ';
        }
      }
    }
    .prod-badge {
      position: absolute;
      top: -.36rem;
      left: -.3rem;
      color: $white;
      font-size: .86rem;
      transform: skew(-5deg, 0deg);
      box-shadow: 0 2px 8px #8496a5;
      .new-prod{
        @include prod-status-badge(#0c9023, rgba(19, 47, 13, 0.88));
      }
      .soon-prod{
        @include prod-status-badge(#ffc100, rgba(156, 135, 7, 0.93));
      }
      .trand-prod{
        @include prod-status-badge(#f83d03, rgba(104, 31, 5, 0.93));
      }
    }
    .prod-manifest {
      position: absolute;
      bottom: .3rem;
      left: -.3rem;
      background: #111199;
      box-shadow: 0 2px 8px #8496a5;
      border-radius: .3rem;
      padding: .1rem .5rem;
      color: $white;
      font-size: .86rem;
      white-space: nowrap;
      transform: skew(-5deg, 0deg);
    }
    .prod-brand {
      position: absolute;
      top: .5rem;
      right: .5rem;
      background: transparent;
      box-shadow: none;
      img {
        background: transparent;
        box-shadow: none;
      }
    }
  }
}

.product {
  ul {
    line-height: 1.1rem;
  }
  li {
    padding: .25rem 0;
  }
  a {
    line-height: .9rem;
    color: theme-color("dark");
    border-bottom: 1px dotted $darkslategray;
    &:hover {
      color: theme-color("primary");
      border-bottom: 1px solid theme-color("primary");
      text-decoration: none;
    }
  }
  .self {
    font-size: .85rem;
    padding: .5rem .85rem;
    border-radius: .3rem;
    border: 1px solid theme-color("primary");
    color: theme-color("primary");
    &:hover {
      text-decoration: none;
    }
  }
  .related-faq {
    &:before {
      font-family: "Material Design Icons";
      content: '\F181';
      font-size: 110%;
      color: theme-color(primary);
      width: 1rem;
      margin-right: .5rem;
    }
  }
  .related-arts {
    &:before {
      font-family: "Material Design Icons";
      content: '\F2FD';
      font-size: 110%;
      color: theme-color(primary);
      width: 1rem;
      margin-right: .5rem;
    }
  }
  .related-products {
    &:before {
      font-family: "Material Design Icons";
      content: '\F191';
      font-size: 110%;
      color: theme-color(primary);
      width: 1rem;
      margin-right: .5rem;
    }
  }
}
.node-brand {
  min-height: 2rem;
  position: absolute;
  top: .6rem;
  left: 1.2rem;
  z-index: 105;
}
.prod-status {
  position: relative;
  div {
    position: absolute;
    top: -1rem;
    right: -1.5rem;
    font-size: .86rem;
    font-weight: bold;
    z-index: 100;
    opacity: .9;
    color: #fff;
    transform: skew(-5deg, 0deg);
    box-shadow: 0 2px 8px #8496a5;
  }
  .inew{
    @include prod-status-badge(#0c9023, rgba(19, 47, 13, 0.88));
    padding: .425rem 1rem;
  }
  .isoon{
    @include prod-status-badge(#ffc100, rgba(156, 135, 7, 0.93));
    padding: .425rem 1rem;
  }
  .ihit{
    @include prod-status-badge(#f83d03, rgba(104, 31, 5, 0.93));
    padding: .425rem 1rem;
  }
}

.set-icons {
  margin: .7rem 0 1.5rem 0;
  padding: 0;
  li {
    list-style: none;
    display: inline-block;
    text-decoration: none;
    margin: .2rem .25rem;
    cursor: help;
    svg {
      cursor: help;
      fill: #2c3034;
      width: 50px;
      height: 50px;
      padding: .25rem;
      background: color("gray-light");
      border-radius: .25rem;
      box-shadow: 0 1px 5px #c0c3c6;
      border: 2px solid $white;
      &:hover {
        fill: $white;
        background: theme-color("primary");;
      }
    }
  }
}
.tab-pills-group {
  margin: 0 auto 1.5rem auto;
  padding: .3rem;
  border-radius: .35rem;
  box-shadow: 0 1px 5px #dfe0e2;
}

.catalog-object {
  .curr-head {
    font-size: 1rem;
    color: $darkslategray;
    font-weight: 700;
  }
  .curr-legend {
    border-bottom: 1px solid color("gray-lighter");
    font-size: .8rem;
    color: theme-color("dark");
  }
  .sku {
    padding: .2rem .2rem;
    background-color: color("gray-lighter");
    font-size: 1.6rem;
  }
  .tech-descriptions {
    width: 100%;
    font-size: .9rem;
    color: $black;
    line-height: 1rem;
    div{
      margin-bottom: .5rem;
      &:nth-child(odd){
        font-size: .8rem;
        padding-bottom: .2rem;
        vertical-align: top;
        color: theme-color("dark");
        border-bottom: 1px dotted color("gray-light");
        &:first-letter{
          text-transform: uppercase;
        }
      }
      &:nth-child(even){
        display: block;
        & span {
          //white-space: nowrap;
          font-size: .7rem;
          line-height: .9rem;
          border-radius: .2rem;
          margin: 0 .1rem .15rem 0;
          padding: .25rem .5rem;
          background: theme-color("light-dark");
          color: $white;
        }
      }
    }
  }
}

.danger {
  background: url("./../images/danger.png") no-repeat;
}

//techelectro.25
.image-grid {
  a {
    border-radius: 1rem;
    width:220px;
    height: 220px;
    img {
      transition: transform 1s ease;
      &:hover {
        transform: scale(1.1) rotate(2deg);
        overflow: hidden;
        object-fit:cover;
        box-shadow: -6px 6px 18px rgba(94, 127, 192, 0.63);
      }
    }
  }
}