/*! nouislider - 13.1.5 - 4/24/2019 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.noUi-target {
  position: relative;
  direction: ltr
}

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1
}

.noUi-connects {
  overflow: hidden;
  z-index: 0
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat
}

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0
}

.noUi-vertical .noUi-origin {
  width: 0
}

.noUi-horizontal .noUi-origin {
  height: 0
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute
}

.noUi-touch-area {
  height: 100%;
  width: 100%
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: transform .3s;
  transition: transform .3s
}

.noUi-state-drag * {
  cursor: inherit !important
}

.noUi-horizontal {
  height: 18px
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px
}

.noUi-vertical {
  width: 18px
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto
}

.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}

.noUi-connects {
  border-radius: 3px
}

.noUi-connect {
  background: #3FB8AF
}

.noUi-draggable {
  cursor: ew-resize
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}

.noUi-handle:after, .noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px
}

.noUi-handle:after {
  left: 17px
}

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px
}

.noUi-vertical .noUi-handle:after {
  top: 17px
}

[disabled] .noUi-connect {
  background: #B8B8B8
}

[disabled] .noUi-handle, [disabled].noUi-handle, [disabled].noUi-target {
  cursor: not-allowed
}

.noUi-pips, .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.noUi-pips {
  position: absolute;
  color: #999
}

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px
}

.noUi-marker {
  position: absolute;
  background: #CCC
}

.noUi-marker-sub {
  background: #AAA
}

.noUi-marker-large {
  background: #AAA
}

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%)
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%)
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px
}

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%)
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%
}





/* No-ui-slider */

.noUi-target {
  border: none;
  box-shadow: none;
  @include border-radius(0px);
  &.noUi-horizontal{
    height: 0.25rem;
    .noUi-handle {
      left: -17px;
    }
  }
  &.noUi-vertical{
    width: 0.25rem;
    height: 156px;
    display: inline-block;
    .noUi-handle {
      left: -6px;
    }
  }

  .noUi-base {
    background: color(gray-lightest);
    box-shadow: none;
    border: none;

    .noUi-connect {
      background: theme-color(primary);
      box-shadow: none;
    }

    .noUi-origin {
      background: theme-color(success);

      .noUi-handle {
        background: $white;
        @include border-radius(100%);
        border:$border-width solid $border-color;
        width: 18px;
        height: 18px;
        top: -7px;
        outline: none;
        @include transition-duration(0.4s);

        &:after,
        &:before {
          display: none;
        }
        &:after {
          display: block;
          position: absolute;
          top:5px;
          left: 0;
          right: 0;
          margin: auto;
          width: 6px;
          height: 6px;
          background: theme-color(primary);
          @include border-radius(100%);
        }
        .noUi-tooltip{
          font-weight: 600;
          @include border-radius(4px);
          border: none;
          line-height: 1;
          font-size: $default-font-size;
          padding: 13px 12px;
          bottom: 190%;
        }
        &.noUi-active {}
      }
    }
  }
  .noUi-pips{
    .noUi-marker-horizontal,.noUi-marker-vertical{
      background: $border-color;
    }
    .noUi-marker-horizontal{
      height: 9px;
      width: 1px;
    }
    .noUi-marker-vertical{
      height: 1px;
      width: 9px;
    }
    .noUi-value{
      color: $black;
      font-size: 0.94rem;
      font-weight: 600;
    }
  }
}

/* Slider Color variations */
@each $color, $value in $theme-colors {
  .slider-#{$color} {
    @include slider-color-variant($value);
  }
}