
$overlay_pattern: '../images/vbg.png';


/* Video overlay and content */
.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none; /* Allows right click menu on the video */
    background: url($overlay_pattern) left top repeat;
    opacity: .7;
}
.video-hero--content {
    position: relative;
    text-align: center;
    color: #FFF;
    margin: 150px 0;
    text-shadow: 0 0 5px rgba(0,0,0,0.4);
}
.video-hero--content h2 {
    font-size: 3rem;
    margin: 0 0 10px;
}
.video-hero--content p {
    font-size: 1.1rem;
    margin: 0;
    padding: .5rem 2rem;
}



/* CSS from jQuery Background Video plugin */
/**
 * Set default positioning as a fallback for if the plugin fails
 */
.jquery-background-video-wrapper {
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}
.jquery-background-video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
/**
 * Fade in videos
 * Note the .js class - so non js users still
 * see the video
 */
.js .jquery-background-video {
    opacity: 0;
    -webkit-transition: opacity 300ms linear;
    transition: opacity 300ms linear;
}
.js .jquery-background-video.is-visible {
    opacity: 1;
}

/**
 * Pause/play button
 */
.jquery-background-video-pauseplay {
    position: absolute;
    background: transparent;
    border: none;
    box-shadow: none;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
    padding: 0;
    cursor: pointer;
    outline: none !important;
}
.jquery-background-video-pauseplay span {
    display: none;
}
.jquery-background-video-pauseplay:after,
.jquery-background-video-pauseplay:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.jquery-background-video-pauseplay.play:before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #FFF;
}
.jquery-background-video-pauseplay.pause:before,
.jquery-background-video-pauseplay.pause:after {
    border-top: 10px solid #FFF;
    border-bottom: 10px solid #FFF;
    border-left: 5px solid #FFF;
}
.jquery-background-video-pauseplay.pause:after {
    left: 10px;
}