/* Images to page */

////////// IMAGE WIDTH PERCENTS ////////
.iw-100 {
  display: inline-block;
  @include customwidth(100);
  width: 100%;
}
.iw-15 {
  @include customwidth(15);
}
.iw-20 {
  @include customwidth(20);
}
.iw-25 {
  @include customwidth(25);
}
.iw-30 {
  @include customwidth(30);
}
.iw-50 {
  @include customwidth(50);
}
.iw-70-block {
  width:70%;
  display:block;
}

.draw {
  max-width: 600px;
  max-height: 300px;
  display: block;
  margin: 1rem auto;
}
.fulldraw {
  display: flex;
  align-items: center;
  justify-content: center;
  .inner {
    align-self: center;
    width: 100%;
    height: 500px;
  }
}


.inc-movie {
  position: relative;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  height: 0;
  overflow: hidden;
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
  }
}