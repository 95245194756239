.s-lightbox-container {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  & *{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.s-lightbox-hidden-scroll {
  overflow: hidden;
}

.s-lightbox-wrapper,
.s-lightbox-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.s-lightbox-overlay{
  background-color: rgba(30,30,30,.8);
}


.s-lightbox-navigation-prev{
  left: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  &:after {
    content: "";
    position: absolute;
    top: 27px;
    width: 46px;
    height: 46px;
    left: 0;
    background-color: rgba(30,30,30,.6);
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 24px 24px;
  }
}
.s-lightbox-navigation-next{
  right: 0;
  &:after {
    content: "";
    position: absolute;
    top: 27px;
    width: 46px;
    height: 46px;
    left: 0;
    background-color: rgba(30,30,30,.6);
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkZGRkZGIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgNGwtMS40MSAxLjQxTDE2LjE3IDExSDR2MmgxMi4xN2wtNS41OCA1LjU5TDEyIDIwbDgtOHoiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 24px 24px;
  }
}
.s-lightbox-navigation-prev,
.s-lightbox-navigation-next{
  position: absolute;
  width: 56px;
  height: 100px;
  top: 50%;
  background-color: transparent;
  margin-top: -50px;
  border: 0;
  cursor: pointer;
  outline: none;
}


.s-lightbox-viewer{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.s-lightbox-items{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.s-lightbox-item{
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transition-duration: 0s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);

}
.s-lightbox-item-first{
  transform: translate(-100%, 0);
}
.s-lightbox-item-active{
  transform: translate(0, 0);
}
.s-lightbox-item-last{
  transform: translate(100%, 0);
}
.s-lightbox-close-overlay .s-lightbox-item-active{
  cursor: pointer;
}
.s-lightbox-item-container{
  left: 60px;
  top: 60px;
  right: 60px;
  bottom: 60px;
  position: absolute;
  display: block;
  /*overflow: hidden;*/
}
.s-lightbox-item-box{
  position: absolute;
  cursor: default;
  opacity: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  transform: scale(0.8);
  transition-duration: .2s;
  transition-property: transform opacity;
  transition-timing-function: ease-in-out;
}
.s-lightbox-draggable-content{
  cursor: grab;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.s-lightbox-zoomable{
  cursor: zoom-in;
}
.s-zoom-active{
  cursor: grab;
}
.s-lightbox-content-box{
  opacity: 0;
  position: absolute;
  cursor: default;
  overflow-y: auto;
  text-align: left;
}
.s-lightbox-view-html{
  padding: 30px;
  background-color: #FFF;
  text-align: left;
}
.s-lightbox-item-image{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  left: 0;
  top: 0;
  max-width: none;
  max-height: none;
}
.s-lightbox-viewer-border .s-lightbox-item-box{
  border: solid 7px #FFF;
  border-radius: 2px;
}
.s-lightbox-youtube-iframe,
.s-lightbox-vimeo-iframe,
.s-lightbox-content-iframe,
.s-lightbox-dailymotion-iframe,
.s-lightbox-googlemap-iframe{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.s-lightbox-content-iframe{
  background-color: #FFF;
}
.s-lightbox-caption{
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  padding: 10px 15px;
  color: #FFF;
  background: rgba(59, 59, 59, 0.48);
}


.s-lightbox-closer{
  position: absolute;
  width: 46px;
  height: 46px;
  border: 0;
  background-color: rgba(30,30,30,.6);
  right: 10px;
  top: 10px;
  cursor: pointer;
  outline: none;
}
.s-lightbox-closer:before, .s-lightbox-closer:after {
  position: absolute;
  left: 22px;
  top: 10px;
  content: '';
  height: 26px;
  width: 2px;
  background-color: #FFF;
}
.s-lightbox-closer:before {
  transform: rotate(45deg);
}
.s-lightbox-closer:after {
  transform: rotate(-45deg);
}

.s-lightbox-closer:hover,
.s-lightbox-navigation-prev:hover:after,
.s-lightbox-navigation-next:hover:after,
.s-lightbox-fullscreen:hover{
  background-color: rgba(0,0,0,.6);
}


.s-lightbox-fullscreen{
  position: absolute;
  width: 46px;
  height: 46px;
  border: 0;
  background-color: rgba(30,30,30,.4);
  right: 10px;
  top: 66px;
  cursor: pointer;
  outline: none;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container{
  position:absolute;
  width:24px;
  height:20px;
  top:13px;
  left: 11px;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span{
  position: absolute;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:before,
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:after{
  position: absolute;
  background-color: rgba(255,255,255,.4);
  content: '';
}

.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(1){
  left:0;
  top:0;
  width:6px;
  height:6px;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(1):before{
  width:6px;
  height:2px;
  left: 0;
  top: 0;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(1):after{
  width:2px;
  height:4px;
  left: 0;
  top: 2px;
}

.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(2){
  top:0;
  right:0;
  width:6px;
  height:6px;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(2):before{
  width:6px;
  height:2px;
  right: 0;
  top: 0;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(2):after{
  width:2px;
  height:4px;
  right:0;
  top: 2px;
}

.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(3){
  bottom:0;
  left:0;
  width:6px;
  height:6px;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(3):before{
  width:6px;
  height:2px;
  bottom:0;
  left:0;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(3):after{
  width:2px;
  height:4px;
  bottom:2px;
  left:0;
}

.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(4){
  bottom:0;
  right:0;
  width:6px;
  height:6px;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(4):before{
  width:6px;
  height:2px;
  bottom:0;
  right:0;
}
.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(4):after{
  width:2px;
  height:4px;
  bottom:2px;
  right:0;
}

.s-lightbox-fullscreen .s-lightbox-fullscreen-container span:nth-child(5){
  left:6px;
  top:6px;
  width:12px;
  height:8px;
  background-color: rgba(255,255,255,.4);
}



.s-lightbox-page-info{
  position: absolute;
  width: 70px;
  height: 30px;
  border: 0;
  background-color: rgba(0,0,0,.8);
  cursor: default;
  outline: none;
  left: 50%;
  margin-left: -35px;
  bottom: 0;
  color: rgba(255,255,255,.6);
  line-height: 30px;
  text-align: center;
  font-size: 12px;
}
.s-lightbox-page-info > span{
  font-weight: bold;
  color: rgba(255,255,255,.8);
}



@media only screen and (max-width: 768px) {
  .s-lightbox-item-container{
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
  }
  .s-lightbox-closer,
  .s-lightbox-navigation-prev:after,
  .s-lightbox-navigation-next:after{
    background-color: rgba(30,30,30,.8);
  }
  .s-lightbox-fullscreen{
    right: 66px;
    top: 10px;
  }
}



/*loader types*/

/*type 1*/
.s-lightbox-loader-type1 {
  width: 40px;
  height: 40px;
  background-color: rgba(255,255,255,.9);
  position: absolute;
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  -webkit-animation: loader-type1 1.2s infinite ease-in-out;
  animation: loader-type1 1.2s infinite ease-in-out;
}

@-webkit-keyframes loader-type1 {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes loader-type1 {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}


/*loader type 2*/
.s-lightbox-loader-type2,
.s-lightbox-loader-type2:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.s-lightbox-loader-type2 {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  text-indent: -9999em;
  border-top: 5px solid rgba(255,255,255, 0.4);
  border-right: 5px solid rgba(255,255,255, 0.4);
  border-bottom: 5px solid rgba(255,255,255, 0.4);
  border-left: 5px solid rgba(255,255,255, 0.9);;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader-type2 1.1s infinite linear;
  animation: loader-type2 1.1s infinite linear;
}
@-webkit-keyframes loader-type2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-type2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lb-closeDownEffect {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 400px, 0);
    transform: translate3d(0, 400px, 0);
  }
}

@keyframes lb-closeDownEffect {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 400px, 0);
    transform: translate3d(0, 400px, 0);
  }
}


@-webkit-keyframes lb-fadeOutEffect {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes lb-fadeOutEffect {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}



/*lightbox close effect styles*/
.s-lightbox-closeEffect .s-lightbox-item-active .s-lightbox-item-container {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  -webkit-animation-name: lb-closeDownEffect;
  animation-name: lb-closeDownEffect;
}

.s-lightbox-closeEffect .s-lightbox-overlay{
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  -webkit-animation-name: lb-fadeOutEffect;
  animation-name: lb-fadeOutEffect;
}
.s-lightbox-closeEffect .s-lightbox-navigation,
.s-lightbox-closeEffect .s-lightbox-closer,
.s-lightbox-closeEffect .s-lightbox-fullscreen,
.s-lightbox-closeEffect .s-lightbox-page-info{
    display: none;
}
