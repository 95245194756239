/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  font-family: $type;
  font-weight: 400;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  box-shadow: $sidebar-box-shadow;
  -webkit-box-shadow: $sidebar-box-shadow;
  -moz-box-shadow: $sidebar-box-shadow;
  -ms-box-shadow: $sidebar-box-shadow;
  border-right: 1px solid #e3e3e3;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .collapse {
        z-index: 999;
        padding-bottom: 1rem;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
        color: $sidebar-light-menu-color;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        position: relative;

        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-right: 1.125rem;
            .rtl & {
              margin-left: 2rem;
              margin-right: 0;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }
          &.menu-arrow {
            position: absolute;
            right: .5rem;
            top: .8rem;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 0;
            color: $sidebar-light-menu-arrow-color;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }

            &:before {
              content: "\F140";
              font-family: "Material Design Icons";
              font-style: normal;
              display: block;
              font-size: 1rem;
              line-height: 10px;
              @include transition(all .2s ease-in);
            }
          }
        }

        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
        }

        .badge {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
        &[aria-expanded="true"] {
          i {
            &.menu-arrow {
              &:before {
                @include rotate(180);
              }
            }
          }
        }
      }

      &.active {
        > .nav-link {
            background: $sidebar-light-menu-active-bg;
            position: relative;
            i,
            .menu-title,
            .menu-arrow {
              color: $sidebar-light-menu-active-color;
              font-weight: bold;
            }
        }
      }
    }

    &:not(.sub-menu) {
      margin-top: .3rem;
      > .nav-item {
        border-bottom: 1px solid $border-color;
        margin-top: 0;
        > .nav-link {
          margin: 0;
        }
        &:hover {
          > .nav-link {
            background: $sidebar-light-menu-hover-bg;
            color: $sidebar-light-menu-hover-color;
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 0;
      margin-top:0;
      list-style: none;
      padding: $sidebar-submenu-padding;

      .nav-item {
        padding: 0;
        &::before {
          content: "\F142";
          font-family: "Material Design Icons";
          position: absolute;
          margin: .1rem 0 0 -.85rem;
          line-height: 1.5rem;
        }
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;          
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &:hover {
            color: $sidebar-light-submenu-hover-color;
          }
          &.active {
            color: $sidebar-light-submenu-active-color;
            background: transparent;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }

  .constant {
    background: rgba(179, 172, 169, 0.15);
    font-weight: 500;
    &:hover{
      box-shadow: 0 2px 8px rgba(154, 147, 140, 0.43);
      .nav-link {
        color: $white;
      }
    }
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}