@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Light.eot');
  src: url('../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Light.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.eot');
  src: url('../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Bold.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Medium.eot');
  src: url('../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Medium.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.eot');
  src: url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Regular.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.eot');
  src: url('../fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Black.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Black.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Play';
  src: url('../fonts/Play/Play-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'slots';
  src:  url('https://res.kvt.su/slots/font/slots.eot?gy1kd5');
  src:  url('https://res.kvt.su/slots/font/slots.eot?gy1kd5#iefix') format('embedded-opentype'),
  url('https://res.kvt.su/slots/font/slots.ttf?gy1kd5') format('truetype'),
  url('https://res.kvt.su/slots/font/slots.woff?gy1kd5') format('woff'),
  url('https://res.kvt.su/slots/font/slots.svg?gy1kd5#slots') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="shape-"], [class*=" shape-"] {
  font-family: 'slots' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  padding: 0 .15rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shape-phillips:before {
  content: "\e900";
}
.shape-posidrive:before {
  content: "\e901";
}
.shape-slotted:before {
  content: "\e902";
}
.shape-hexagonal:before {
  content: "\e903";
}
.shape-hex-key:before {
  content: "\e904";
}
.shape-pyramidal-key:before {
  content: "\e905";
}
.shape-ledge-key:before {
  content: "\e906";
}
.shape-shape-key:before {
  content: "\e907";
}
.shape-sloping-key:before {
  content: "\e908";
}
.shape-square-key:before {
  content: "\e909";
}
.shape-triangle-key:before {
  content: "\e90a";
}
.shape-torx-key:before {
  content: "\e90b";
}
.shape-plus-minus::before {
  content: "\e90c";
}


@font-face {
  font-family: 'socialnetworks';
  src:
          url('../fonts/social/socialnetworks.ttf?duogft') format('truetype'),
          url('../fonts/social/socialnetworks.woff?duogft') format('woff'),
          url('../fonts/social/socialnetworks.svg?duogft#socialnetworks') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="sn-"], [class*=" sn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'socialnetworks' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sn-you_tube:before {
  content: "\e900";
}
.sn-vk:before {
  content: "\e901";
}
.sn-telegram:before {
  content: "\e902";
}
.sn-yandex_zen:before {
  content: "\e903";
}
.sn-rtb:before {
  content: "\e904";
}
.sn-youtube:before {
  content: "\e905";
}
.sn-facebook:before {
  content: "\e906";
}
.sn-instagram:before {
  content: "\e907";
}
.sn-tiktok:before {
  content: "\e908";
}
.sn-vkvideo:before {
  content: "\e909";
}
