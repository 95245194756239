// Cards Mixins 

@mixin card-inverse-variant($bg, $border, $color) {
  background: $bg;
  border: 1px solid $border;
  color: $color;
}

@mixin prod-status-badge($bg, $shade) {
  padding: .2rem .6rem;
  border-radius: .3rem;
  background-color: $bg;
  text-shadow: 0 1px 3px $shade;
}
