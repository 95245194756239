/* Footer */

.footer {
  background: $footer-bg;
  color: $footer-color;
  padding: 1rem 1rem;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $type;
  font-weight: 400;
  border-top: 1px solid rgba($black, .06);
  a {
    color: theme-color(primary);
    font-size: inherit;
  }
  .social {
    a {
      -webkit-appearance: button;
      margin: .2rem;
    }
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.footer-menu {
  & li{
    list-style: none;
    padding: 0 .7rem 0 0;
    &:before {
      font-family: "Material Design Icons";
      content: '\F142';
      color: theme-color(dark);
      width: .8rem;
      margin-right: .2rem;
    }
  }
}