/*!
 * Select2 Bootstrap Theme v0.1.0-beta.10 (https://select2.github.io/select2-bootstrap-theme)
 * Copyright 2015-2017 Florian Kissling and contributors (https://github.com/select2/select2-bootstrap-theme/graphs/contributors)
 * Licensed under MIT (https://github.com/select2/select2-bootstrap-theme/blob/master/LICENSE)
 */

.select2-container--bootstrap {
  display:block
}
.select2-container--bootstrap .select2-selection {
  -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow:inset 0 1px 1px rgba(0,0,0,.075);
  background-color:#fff;
  border:1px solid #ccc;
  border-radius:4px;
  color:#555;
  font-size:14px;
  outline:0
}
.select2-container--bootstrap .select2-selection.form-control {
  border-radius:4px
}
.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
  -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow:inset 0 1px 1px rgba(0,0,0,.075);
  background-color:#fff;
  border:1px solid #ccc;
  border-radius:4px;
  color:#555;
  font-size:14px
}
.select2-container--bootstrap .select2-search__field {
  outline:0
}
.select2-container--bootstrap .select2-search__field::-webkit-input-placeholder {
  color:#999
}
.select2-container--bootstrap .select2-search__field:-moz-placeholder {
  color:#999
}
.select2-container--bootstrap .select2-search__field::-moz-placeholder {
  color:#999;
  opacity:1
}
.select2-container--bootstrap .select2-search__field:-ms-input-placeholder {
  color:#999
}
.select2-container--bootstrap .select2-results__option {
  padding:6px 12px
}
.select2-container--bootstrap .select2-results__option[role=group] {
  padding:0
}
.select2-container--bootstrap .select2-results__option[aria-disabled=true] {
  color:#777;
  cursor:not-allowed
}
.select2-container--bootstrap .select2-results__option[aria-selected=true] {
  background-color:#f5f5f5;
  color:#262626
}
.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
  background-color:#337ab7;
  color:#fff
}
.select2-container--bootstrap .select2-results__option .select2-results__option {
  padding:6px 12px
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__group {
  padding-left:0
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option {
  margin-left:-12px;
  padding-left:24px
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left:-24px;
  padding-left:36px
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left:-36px;
  padding-left:48px
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left:-48px;
  padding-left:60px
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left:-60px;
  padding-left:72px
}
.select2-container--bootstrap .select2-results__group {
  color:#777;
  display:block;
  padding:6px 12px;
  font-size:12px;
  line-height:1.42857143;
  white-space:nowrap
}
.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  -o-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -webkit-transition:border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition:border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  border-color:#66afe9
}
.select2-container--bootstrap.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color:transparent transparent #999;
  border-width:0 4px 4px
}
.select2-container--bootstrap.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
  border-bottom-color:transparent
}
.select2-container--bootstrap.select2-container--open.select2-container--above .select2-selection {
  border-top-right-radius:0;
  border-top-left-radius:0;
  border-top-color:transparent
}
.select2-container--bootstrap .select2-selection__clear {
  color:#999;
  cursor:pointer;
  float:right;
  font-weight:700;
  margin-right:10px
}
.select2-container--bootstrap .select2-selection__clear:hover {
  color:#333
}
.select2-container--bootstrap.select2-container--disabled .select2-selection {
  border-color:#ccc;
  -webkit-box-shadow:none;
  box-shadow:none
}
.select2-container--bootstrap.select2-container--disabled .select2-search__field,
.select2-container--bootstrap.select2-container--disabled .select2-selection {
  cursor:not-allowed
}
.select2-container--bootstrap.select2-container--disabled .select2-selection,
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color:#eee
}
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--bootstrap.select2-container--disabled .select2-selection__clear {
  display:none
}
.select2-container--bootstrap .select2-dropdown {
  -webkit-box-shadow:0 6px 12px rgba(0,0,0,.175);
  box-shadow:0 6px 12px rgba(0,0,0,.175);
  border-color:#66afe9;
  overflow-x:hidden;
  margin-top:-1px
}
.select2-container--bootstrap .select2-dropdown--above {
  -webkit-box-shadow:0 -6px 12px rgba(0,0,0,.175);
  box-shadow:0 -6px 12px rgba(0,0,0,.175);
  margin-top:1px
}
.select2-container--bootstrap .select2-results>.select2-results__options {
  max-height:200px;
  overflow-y:auto
}
.select2-container--bootstrap .select2-selection--single {
  height:34px;
  line-height:1.42857143;
  padding:6px 24px 6px 12px
}
.select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
  position:absolute;
  bottom:0;
  right:12px;
  top:0;
  width:4px
}
.select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
  border-color:#999 transparent transparent;
  border-style:solid;
  border-width:4px 4px 0;
  height:0;
  left:0;
  margin-left:-4px;
  margin-top:-2px;
  position:absolute;
  top:50%;
  width:0
}
.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
  color:#555;
  padding:0
}
.select2-container--bootstrap .select2-selection--single .select2-selection__placeholder {
  color:#999
}
.select2-container--bootstrap .select2-selection--multiple {
  min-height:34px;
  padding:0;
  height:auto
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
  display:block;
  line-height:1.42857143;
  list-style:none;
  margin:0;
  overflow:hidden;
  padding:0;
  width:100%;
  text-overflow:ellipsis;
  white-space:nowrap
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__placeholder {
  color:#999;
  float:left;
  margin-top:5px
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
  color:#555;
  background:#fff;
  border:1px solid #ccc;
  border-radius:4px;
  cursor:default;
  float:left;
  margin:5px 0 0 6px;
  padding:0 6px
}
.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  background:0 0;
  padding:0 12px;
  height:32px;
  line-height:1.42857143;
  margin-top:0;
  min-width:5em
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
  color:#999;
  cursor:pointer;
  display:inline-block;
  font-weight:700;
  margin-right:3px
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
  color:#333
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
  margin-top:6px
}
.form-group-sm .select2-container--bootstrap .select2-selection--single,
.input-group-sm .select2-container--bootstrap .select2-selection--single,
.select2-container--bootstrap .select2-selection--single.input-sm {
  border-radius:3px;
  font-size:12px;
  height:30px;
  line-height:1.5;
  padding:5px 22px 5px 10px
}
.form-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.input-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.select2-container--bootstrap .select2-selection--single.input-sm .select2-selection__arrow b {
  margin-left:-5px
}
.form-group-sm .select2-container--bootstrap .select2-selection--multiple,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple,
.select2-container--bootstrap .select2-selection--multiple.input-sm {
  min-height:30px;
  border-radius:3px
}
.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-selection__choice {
  font-size:12px;
  line-height:1.5;
  margin:4px 0 0 5px;
  padding:0 5px
}
.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-search--inline .select2-search__field {
  padding:0 10px;
  font-size:12px;
  height:28px;
  line-height:1.5
}
.form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.select2-container--bootstrap .select2-selection--multiple.input-sm .select2-selection__clear {
  margin-top:5px
}
.form-group-lg .select2-container--bootstrap .select2-selection--single,
.input-group-lg .select2-container--bootstrap .select2-selection--single,
.select2-container--bootstrap .select2-selection--single.input-lg {
  border-radius:6px;
  font-size:18px;
  height:46px;
  line-height:1.3333333;
  padding:10px 31px 10px 16px
}
.form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow,
.input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow,
.select2-container--bootstrap .select2-selection--single.input-lg .select2-selection__arrow {
  width:5px
}
.form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b,
.select2-container--bootstrap .select2-selection--single.input-lg .select2-selection__arrow b {
  border-width:5px 5px 0;
  margin-left:-10px;
  margin-top:-2.5px
}
.form-group-lg .select2-container--bootstrap .select2-selection--multiple,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple,
.select2-container--bootstrap .select2-selection--multiple.input-lg {
  min-height:46px;
  border-radius:6px
}
.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-selection__choice {
  font-size:18px;
  line-height:1.3333333;
  border-radius:4px;
  margin:9px 0 0 8px;
  padding:0 10px
}
.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field,
.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-search--inline .select2-search__field {
  padding:0 16px;
  font-size:18px;
  height:44px;
  line-height:1.3333333
}
.form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear,
.select2-container--bootstrap .select2-selection--multiple.input-lg .select2-selection__clear {
  margin-top:10px
}
.input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single .select2-selection__arrow b,
.select2-container--bootstrap .select2-selection.input-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color:transparent transparent #999;
  border-width:0 5px 5px
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single {
  padding-left:24px;
  padding-right:12px
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right:0;
  padding-left:0;
  text-align:right
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__clear {
  float:left
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left:12px;
  right:auto
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__arrow b {
  margin-left:0
}
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-search--inline,
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__placeholder {
  float:right
}
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left:0;
  margin-right:6px
}
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left:2px;
  margin-right:auto
}
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color:#8a6d3b
}
.has-warning .select2-container--focus .select2-selection,
.has-warning .select2-container--open .select2-selection {
  -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #c0a16b;
  box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #c0a16b;
  border-color:#66512c
}
.has-warning.select2-drop-active {
  border-color:#66512c
}
.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color:#66512c
}
.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color:#a94442
}
.has-error .select2-container--focus .select2-selection,
.has-error .select2-container--open .select2-selection {
  -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ce8483;
  box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ce8483;
  border-color:#843534
}
.has-error.select2-drop-active {
  border-color:#843534
}
.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color:#843534
}
.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color:#3c763d
}
.has-success .select2-container--focus .select2-selection,
.has-success .select2-container--open .select2-selection {
  -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #67b168;
  box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #67b168;
  border-color:#2b542c
}
.has-success.select2-drop-active {
  border-color:#2b542c
}
.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color:#2b542c
}
.input-group>.select2-hidden-accessible:first-child+.select2-container--bootstrap>.selection>.select2-selection,
.input-group>.select2-hidden-accessible:first-child+.select2-container--bootstrap>.selection>.select2-selection.form-control {
  border-bottom-right-radius:0;
  border-top-right-radius:0
}
.input-group>.select2-hidden-accessible:not(:first-child)+.select2-container--bootstrap:not(:last-child)>.selection>.select2-selection,
.input-group>.select2-hidden-accessible:not(:first-child)+.select2-container--bootstrap:not(:last-child)>.selection>.select2-selection.form-control {
  border-radius:0
}
.input-group>.select2-hidden-accessible:not(:first-child):not(:last-child)+.select2-container--bootstrap:last-child>.selection>.select2-selection,
.input-group>.select2-hidden-accessible:not(:first-child):not(:last-child)+.select2-container--bootstrap:last-child>.selection>.select2-selection.form-control {
  border-bottom-left-radius:0;
  border-top-left-radius:0
}
.input-group>.select2-container--bootstrap {
  display:table;
  table-layout:fixed;
  position:relative;
  z-index:2;
  width:100%;
  margin-bottom:0
}
.input-group>.select2-container--bootstrap>.selection>.select2-selection.form-control {
  float:none
}
.input-group>.select2-container--bootstrap.select2-container--focus,
.input-group>.select2-container--bootstrap.select2-container--open {
  z-index:3
}
.input-group>.select2-container--bootstrap,
.input-group>.select2-container--bootstrap .input-group-btn,
.input-group>.select2-container--bootstrap .input-group-btn .btn {
  vertical-align:top
}
.form-control.select2-hidden-accessible {
  position:absolute!important;
  width:1px!important
}
@media (min-width:768px) {
  .form-inline .select2-container--bootstrap {
    display:inline-block
  }
}
