/* Base initial Styles */
// Add spacing to Boostrap components for demo purpose

.template-demo {
  > .btn {
    @extend .mt-3;
    @extend .mr-2;
  }

  > .btn-group,
  >  .btn-group-vertical {
    @extend .mt-2;
    @extend .mr-2;
  }


  .progress {
    margin-top: 1.5rem;
  }

  > h2,
   > h3,
   > h4,
   > h5,
   > h6,
  > h1 {
    border-top: 1px solid $border-color;
    padding: 0.5rem 0 0;
  }

  > .dropdown {
    display: inline-block;
    @extend .mr-2;
    margin-bottom: 0.5rem;
  }
}

.documentation {
  padding-top: 0;
  max-width: 100%;
  .content-wrapper {
    padding-top: 0;
    min-height: calc(100vh - #{$footer-height});
  }
}

.doc-header {
  position: fixed;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 2;
  background: $content-bg;
}

.doc-content {
  padding-top: 157px;
}

.object-pdf {
  object {
    width: 100%;
    height: 750px;
  }
}

.dashboard-chart-legend {
  list-style-type: none;
  padding-left: 0;
  li {
    display: inline;
    span {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 7px;
    }
    &:not(:first-child) {
      span {
        margin-left: 25px;
        @media(max-width: 576px) {
          margin-left: 13px;
        }
      }
    }
  }
}

.dashboard-tabs {
  .item {
    border-bottom: 1px solid $border-color;
    @media (min-width: 577px) {
      border-bottom: 0;
    }
  }
}

.nav-tabs {
	border: none;
	.nav-link {
		background: $nav-tabs-link-bg;
		color: $nav-tabs-link-color;
		border-radius: 0;
        line-height: 1.3rem;
		border: 1px solid $border-color;
		padding: .3rem .7rem .1rem .7rem;
		@media (max-width: 767px) {
			padding: .3rem .3rem .1rem .3rem;
		}
	}
	.nav-item {
		.nav-link {
			border: 0;
			background: transparent;
			&.active {
				border: 0;
				border-bottom: 5px solid theme-color(primary);
				color: theme-color(primary);
			}
		}
	}
}

.pills-table {
  font-size: 1rem;
  margin: 1rem 0 1rem .75rem;
  .nav-link {
    @include border-radius($nav-pills-border-radius);
    padding: .3rem .8rem;
    i {
      padding-right: .3rem;
    }
  }
  .nav-link.active,
  .show > .nav-link {
    color: $white;
    background-color: theme-color("primary");
  }
}

.link-file{
  color: theme-color(light-dark);
  text-decoration-line: underline;
  text-decoration-color: color(gray-lighter);
  &:hover {
    color: theme-color(indigo);
    text-decoration-color: color(gray-light);
  }
}
code {
  color: $main-code-color;
}

.breadcrumbs {
  font-size: .77rem;
  i:first-child {
    font-size: 1rem;
    line-height: 1rem;
  }
  a {
    font-size: .78rem;
    padding: 0 .3rem;
    &:last-child {
      cursor: default;
      text-decoration: none;
    }
  }
}
