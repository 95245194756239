
.cookie {
  box-sizing: border-box;
  position: fixed;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translate(-50%,110%);
  -ms-transform: translate(-50%,110%);
  transform: translate(-50%,110%);
  z-index: 50;
  padding: 0 2rem;
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
.js-cookie--showed {
  -webkit-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
  opacity: 1;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}