/*
 * 	Default theme - Owl Carousel CSS File
 */

$owl-color-base:			#869791 !default;
$owl-color-white:			#FFF !default;
$owl-color-gray:			#D6D6D6 !default;

//nav

$owl-nav-color: 			$owl-color-white !default;
$owl-nav-color-hover:		$owl-color-white !default;
$owl-nav-font-size: 		14px !default;
$owl-nav-rounded: 			3px !default;
$owl-nav-margin: 			5px !default;
$owl-nav-padding: 			4px 7px !default;
$owl-nav-background: 		$owl-color-gray !default;
$owl-nav-background-hover:	$owl-color-base !default;
$owl-nav-disabled-opacity: 	0.5 !default;

//dots

$owl-dot-width:				10px !default;
$owl-dot-height:			10px !default;
$owl-dot-rounded:			30px !default;
$owl-dot-margin: 			5px 7px !default;
$owl-dot-background:		$owl-color-gray !default;
$owl-dot-background-active:	$owl-color-base !default;

.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    position: absolute;
    top: 35%;
    width: 100%;
    margin-top: 0;
    -webkit-tap-highlight-color: transparent;

    [class*='owl-'] {
      color: $owl-nav-color;
      font-size: $owl-nav-font-size;
      margin: $owl-nav-margin;
      padding: $owl-nav-padding;
      background: $owl-nav-background;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        color:$owl-nav-color-hover;
        text-decoration: none;
      }
    }
    .owl-prev,
    .owl-next {
      background: transparent;
      color: $white;
      i {
        &:before {
          width: 60px;
          height: 60px;
          background: rgba($black, .5);
          border-radius: 100%;
          font-size: 1.875rem;
          font-weight: bold;
          line-height: 2;
          text-align: center;
        }
      }
    }
    .owl-prev {
      float: left;
    }
    .owl-next {
      float: right;
    }
    .disabled {
      opacity: $owl-nav-disabled-opacity;
      cursor: default;
    }
  }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: $owl-dot-width;
        height: $owl-dot-height;
        margin: $owl-dot-margin;
        background: $owl-dot-background;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: $owl-dot-rounded;
      }

      &.active,
      &:hover {
        span {
          background: $owl-dot-background-active;
        }
      }
    }
  }
}