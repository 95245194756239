/* Tables */

.table {
  margin-bottom: 0;
  font-size: .86rem;
  color: $table-text-color;
  font-weight: 300;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 500;
      font-size: .85rem;
      background: $table-accent-bg;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    text-wrap: normal;
    vertical-align: middle;
    line-height: 1;
  }
  &.dark-head {
    th {
      background: $table-header-bg;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
  &.table-bordered {
    border: 1px solid $table-border-color;
  }
  &.products {
    th {
      background: $table-header-bg;
    }
  }
}
.kit-content table {
  font-size: .8rem;
  color: $table-text-color;
  font-weight: 300;
  th,
  td {
    text-wrap: normal;
    vertical-align: middle;
    line-height: 1;
    padding: .2rem .5rem;
    border: 1px solid $table-border-color;
  }
  th {
    border-bottom-width: 1px;
    padding: .5rem .5rem;
    font-weight: 500;
    background: $table-header-bg;
  }
  tr:hover {
    background-color: $table-hover-bg;
  }
  a {
    color: $table-text-href;
  }
}
.products {
  td {
    text-wrap: normal;
    vertical-align: middle;
    line-height: 1;
    opacity: .76;
    position: relative;
    &:nth-child(1) {
      width: 1rem;
      padding: 0.6rem 0.6rem;
    }
    &:nth-child(2) {
      padding-right: 1rem;
    }
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
    a {
      border: none;
      font-weight: 400;
      color: theme-color("primary");
      &:hover {
        border: none;
        text-decoration: underline;
        text-decoration-color: rgba(240, 86, 14, 0.35);
      }
    }
    .badge {
      display: block;
      margin: 0 auto;
      font-size: .725rem;
      font-weight: 400;
      line-height: 1;
      padding: .2rem;
      border-radius: .1rem;
      background: $table-badge-color;
      color: color(white);
    }
  }
  .price {
    text-align: right;
    background-color: rgba(154, 222, 128, 0.15);
  }
  .status-badge {
    position: absolute;
    top: .5rem;
    left: -.5rem;
    margin: 0 0;
    display: block;
    width: 1rem;
    height: 1rem;
  }
  .status-action {
    color: color(white);
    border-radius: .5rem .5rem;
    background-color: theme-color('primary');
    padding: .1rem 0 0 .1rem;
    i {
      text-shadow: 0 2px 1px #562205;
    }
  }
  .status-soon {
    border-radius: .5rem .5rem;
    background-color: theme-color('warning');
    padding: 0 0 0 .1rem;
  }
  .status-new {
    color: theme-color('success');
    font-size: 1.2rem;
    margin-left: -.1rem;
  }
  .status-order {
    border-radius: .1rem .1rem;
    background-color: theme-color('primary');
    padding: .15rem 0 0 .1rem;
    width: 1.15rem;
    height: 1.25rem;
    color: color(white);
    i {
      text-shadow: 0 2px 1px #562205;
    }
  }
  .color-badge {
    position: absolute;
    top: 8%;
    right: .1rem;
    margin: 0 0;
    display: block;
    border-radius: .25rem;
    width: .5rem;
    height: 84%;
    border: 1px solid #555;
  }
  .multicolor {
    position: absolute;
    margin: 0 0;
    top: 8%;
    right: 0;
    height: 84%;
    display: flex;
    align-items: stretch;
    align-content: flex-end;
    .sc {
      border-radius: .25rem;
      width: .5rem;
      height: 100%;
      border: 1px solid #555;
      margin-right: .1rem;
    }
  }
  .cb-white {
    background: #fff;
  }
  .cb-black {
    background: #252525;
    border-color: #000;
  }
  .cb-gray {
    background: #7a7a7a;
  }
  .cb-red {
    background: #ff0707;
    border-color: #c60b0b;
  }
  .cb-orange {
    background: #f15006;
    border-color: #ba3d0a;
  }
  .cb-beige {
    background: #d0b28c;
    border-color: #8e6a49;
  }
  .cb-blue {
    background: #1517ad;
    border-color: #0d0e6b;
  }
  .cb-aqua {
    background: #24a2e9;
    border-color: #0a5e9c;
  }
  .cb-violet {
    background: #960988;
    border-color: #6b0661;
  }
  .cb-yellow {
    background: rgba(255, 236, 50, 0.87);
    border-color: #938011;
  }
  .cb-green {
    background: rgba(17, 118, 24, 0.82);
    border-color: #074208;
  }
  .cb-turq {
    background: rgba(38, 162, 160, 0.78);
    border-color: #0e5558;
  }
  .cb-steel {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(186,186,186,1) 50%, rgba(255,255,255,1) 100%);
  }
  .cb-transparent {
    background: #fff;
    opacity: .35;
  }
  .cb-brickred {
    background: #d21515;
    border-color: #57160e;
  }
  .cb-ygreen {
    background: rgb(232,233,13);
    background: linear-gradient(180deg, rgba(232,233,13,1) 0%, rgba(232,233,13,1) 49%, rgba(29,155,17,1) 51%, rgba(29,155,17,1) 100%);
    border-color: #465113;
  }
  .cb-yblack {
    background: rgb(240, 241, 8);
    background: linear-gradient(
      135deg,
      rgb(226, 215, 156) 0%,
      rgb(250, 218, 4) 49%,
      rgb(38, 41, 45) 51%,
      rgb(38, 41, 45) 100% );
    border-color: #000;
  }
  .cb-redwhite {
    background: rgb(236, 227, 226);
    background: linear-gradient(
      135deg,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 49%,
      rgb(175, 11, 11) 51%,
      rgb(175, 11, 11) 100% );
    border-color: #570503;
  }
}

.compare-table {
  thead th {
    background-color: $white;
    position: relative;
    a:hover {
      text-decoration: none;
    }
    .title {
      display: block;
      font-size: 1rem;
    }
    .delete {
      position: absolute;
      top: .3rem;
      left: .3rem;
      &:before {
        font-family: "Material Design Icons";
        font-size: 2rem;
        content: '\F157';
        color: theme-color(primary);
      }
    }
  }
  td {
    text-wrap: normal;
    vertical-align: middle;
    line-height: 1;
    &:nth-child(1) {
      min-width: 15%;
      padding-right: 1rem;
    }
  }
}


.table-striped {
  background-color: #f3f3f3;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #e8e8e8;
}
.table-hover > tbody > tr:hover {
  background-color: hsl(50, 51%, 83%);
  box-shadow: inset 0 0 3px 1px #cfc28c;
}
.main-items {
  font-size: 1rem;
  margin: .2rem 0 0 .75rem;
  color: $darkslategray;
  &:before {
    font-family: "Material Design Icons";
    content: '\F5D8';
    width: .8rem;
    margin-right: .2rem;
  }
}