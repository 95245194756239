/* Tooltips */

$tooltip-color: rgba(19, 19, 26, 0.9);

.tooltip {
  font-size: .76rem;
  min-width: 5.625rem;
  .tooltip-inner {
    font-weight: 400;
    background-color: $tooltip-color;
  }
  z-index: 1029;
}

.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: $tooltip-color;
    }
  }
}
.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: $tooltip-color;
    }
  }
}
.bs-tooltip-left {
  .arrow {
    &::before {
      border-left-color: $tooltip-color;
    }
  }
}
.bs-tooltip-right {
  .arrow {
    &::before {
      border-right-color: $tooltip-color;
    }
  }
}

.tooltip-inner {
  border-radius: .125rem;
}