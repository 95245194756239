/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden; /* fix firefox animation glitch */
  }

  .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }

  .owl-wrapper,
  .owl-item{
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
  }

  .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  .owl-item img {
    display: block;
    width: 100%;
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-nav button.owl-prev,
  .owl-nav button.owl-next,
  button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding:0!important;
    font: inherit;
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-refresh .owl-item {
    visibility: hidden;
  }

  &.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel{
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }
  .owl-animated-in {
    z-index: 0;
  }
  .owl-animated-out {
    z-index: 1;
  }
  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease;
    }

    /**
        This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
        calculation of the height of the owl-item that breaks page layouts
     */
    .owl-lazy[src^=""], .owl-lazy:not([src]) {
      max-height: 0;
    }

    img.owl-lazy {
      transform-style: preserve-3d;
    }
  }
}
$owl-image-path: "" !default;

/*
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel{
  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("#{$owl-image-path}owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease;
  }

  .owl-video-play-icon:hover {
    transform: scale(1.3, 1.3);
  }

  .owl-video-playing .owl-video-tn,
  .owl-video-playing .owl-video-play-icon {
    display: none;
  }

  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
  }

  .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}


.card-carousel {
  .carousel-control-prev,
  .carousel-control-next {
    background: color("gray-lighter");
    width: 2rem;
    height: 2rem;
    top: 1rem;
    border-radius: 0.275rem;
    opacity: 1;
    &:hover {
      opacity: .7;
    }
    &.control-light {
      border-color: theme-color("primary");
    }
  }
  .carousel-control-prev {
    right: 3.6rem;
    left: auto;
    .carousel-control-prev-icon {
      background-image: none;
      &::after {
        font-family: "Material Design Icons";
        content: '\F04D';
        color: theme-color("dark");
        font-weight: bold;
        font-size: 1rem;
        margin-right: .1rem;
        display: block;
        line-height: 1.15rem;
      }
    }
    &.control-light {
      .carousel-control-prev-icon {
        &::after {
          color: theme-color("primary");
        }
      }
    }
  }
  .carousel-control-next {
    right: 1.2rem;
    .carousel-control-next-icon {
      background-image: none;
      &::after {
        font-family: "Material Design Icons";
        content: '\F054';
        color: theme-color("dark");
        font-weight: bold;
        font-size: 1rem;
        display: block;
        line-height: 1.15rem;
      }
    }
    &.control-light {
      .carousel-control-next-icon {
        &::after {
          color: theme-color("primary");
        }
      }
    }
  }
}