/* Reset Styles */

body {
	padding: 0;
	margin: 0;
	overflow-x: hidden;
}

.form-control {
	&,
	&:focus {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
        outline: 0;		
	}
}

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
    text-shadow: none
}

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file]>input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
    outline: 0
}

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;
}

textarea {
    resize: none;
    overflow-x: hidden;
}

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
    outline: 0;
    outline-offset: 0
}

.bootstrap-select .dropdown-toggle:focus {
    outline: 0 ;
    outline-offset: 0;
}

.dropdown-menu>li>a:active,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:visited {
    outline: 0;
}

a {
  color: color(gray);
  text-decoration: none;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  &:hover {
    opacity: .9;
    color: $black;
    text-decoration: underline;
  }
}

a:focus,
input:focus {
    border-color: transparent;
    outline: none
}


//Reset bootstrap classes
.page-item.disable .page-link{
  background-color: $white-smoke;
  color: color(gray-light);
}

.nav-pills {
  .nav-link {
    @include border-radius(.25rem);
    padding: .25rem .5rem;
    margin-right: .5rem;
    background-color: color("gray-lightest");
    &:hover {
      color: theme-color("primary");
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: $white;
    background-color: theme-color("primary");
  }
}

.fixed-top-admin {
  top: 36px;
}