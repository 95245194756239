/* Navbar */

.navbar {
  font-family: $play;
  font-weight: 500;
  -webkit-transition-duration: 3s;
  -moz-transition-duration: 3s;
  -o-transition-duration: 3s;
  transition-duration: 3s;
  transition: background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: background $action-transition-duration $action-transition-timing-function;
  -moz-transition: background $action-transition-duration $action-transition-timing-function;
  -ms-transition: background $action-transition-duration $action-transition-timing-function;
  box-shadow: 0 6px 12px -7px #DFC61D;
  -webkit-box-shadow: 0 6px 12px -7px #F27C26;
  -moz-box-shadow: 0 6px 12px -7px #DFC61D;

  .navbar-brand-wrapper {
    background: $navbar-default-bg;
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    width: $sidebar-width-lg;
    height: $navbar-height;
    .navbar-brand-inner-wrapper {
      margin-left: 1.375rem;
      margin-right: 1.375rem;
      @media(max-width:480px) {
        margin-right: .75rem;
      }
      .navbar-brand {
        color: lighten(color(gray-dark), 20%);
        font-size: 1.5rem;
        margin-right: 0;
        padding: .25rem 0;
        &.brand-logo-mini {
          display: none;
          @media(max-width:1089px) {
            display: inline-block;
            width: 1.875rem;
          }
        }

        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }

        img {
          width: calc(#{$sidebar-width-lg} - 80px );
          max-width: 100%;
          height: 34px;
          margin: auto;
          vertical-align: middle;
          -webkit-filter: drop-shadow( 0px 1px 5px #111 );
          filter: drop-shadow( 0px 1px 5px #111 );
        }
      }

      .navbar-toggler {
        border: 0;
        color: $white;
        font-size: 1.7rem;
        padding: 0;
        &:not(.navbar-toggler-right) {
          @media(max-width: 1089px) {
            display: none;
          }
        }
      }

      .brand-logo-mini {
        padding-left: 0;
        text-align: center;
        img {
          width: calc(#{$sidebar-width-icon} - 30px );
          max-width: 100%;
          margin: auto;
        }
      }
    }  
  }

  .navbar-menu-wrapper {
    background: $navbar-default-bg;
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    padding-left: 1.062rem;
    padding-right: 1.062rem;
    width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;
    
    @media (max-width: 1089px) {
      width: calc(100% - 55px);
      padding-left: 15px;
      padding-right: 15px;
    }
    @media(max-width:480px) {
      padding-left: 0rem;
    }

    .navbar-toggler {
      border: 0;
      color: inherit;
      font-size: 1.5rem;
      padding: 0;
      &:not(.navbar-toggler-right) {
        @media (max-width: 1089px) {
          display: none;
        }
      }
      &.navbar-toggler-right{
        @media (max-width: 1089px){
          padding-left: 15px;
          padding-right: 11px;
        }
      }
    }

    .navbar-nav {
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;
      .nav-item {
        margin-left: 1rem;
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          color: inherit;
          padding: .05rem .25rem;
          font-size: $navbar-font-size;
          border: 1px dotted #575757;
          border-radius: 5px 5px;         
          &:hover {
            border: 1px solid #ACACAC;
            border-radius: 5px 5px;
            background-color: $navbar-hover-link;
          }
          &.phone {
            background: $navbar-default-bg;
            border: 1px solid $navbar-default-bg;
            border-radius: 50% 50%;
            position: relative;
            text-align: center;
            font-size: 2rem;
            width: 2.1rem;
            height: 2.1rem;
            color: theme-color(primary);
            &:hover {
              opacity: .8;
            }
          }
        }
        &.nav-search {
          margin-left: 0rem;
          .input-group {
            background: #ececec;
            border-radius: 4px;
            padding: 0 .75rem;
            .form-control,
            .input-group-text {
              background: transparent;
              border: 0;
              color: #000;
              padding: 0;
            }
            .input-group-text {
              i {
                color: #9b9b9b;
              }
            }
            .form-control {
              margin-left: .5rem;
              height: 2.5rem;
              @include placeholder {
                color: #9b9b9b;
              }
            }
          }
        }
        &.nav-settings {
          @extend .align-self-stretch;
          @extend .align-items-center;
          margin: 0;
          padding-left: 1.5rem;
          .nav-link {
            padding: 0;
            text-align: center;
          }
          i {
            font-size: 1.5rem;
            vertical-align: middle;
          }
        }
        &.nav-main {
          @extend .d-flex;
          @extend .align-items-center;
          margin-left: .3rem;
          margin-right: .3rem;
          white-space: nowrap;
          @media (max-width: 560px) {
            margin-left: .1rem;
            margin-right: .1rem;
          }
          .nav-link {
            img {
              width: 25px;
              height: 25px;
              border-radius: 100%;
            }
            .nav-main-name {
              margin-left: .25rem;
              margin-right: 0;
              color: $white;
              font-weight: 500;
              @media (max-width: 919px) {
                display: none;
              }
            }
            &::after {
              color: $white;
            }
          }  
        }
        &.dropdown {
          .dropdown-menu {
            @extend .dropdownAnimation;        
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
          }
          .navbar-dropdown {
            position: absolute;            
            font-size: 0.9rem;
            margin-top: 0;
            right: 0;
            left: auto;
            top: $navbar-height;
            .rtl & {
              right: auto;
              left: 0;
            }

            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              padding: .65rem 1.5rem;
              cursor: pointer;
              .item-thumbnail {
                img {
                  width: 2.25rem;
                  height: 2.25rem;
                  border-radius: 50%;
                }
                .item-icon {
                  width: 2.25rem;
                  height: 2.25rem;
                  border-radius: 50%;
                  @extend .d-flex;
                  @extend .align-items-center;
                  @extend .justify-content-center;
                  color: $white;
                  i {
                    font-size: 17px;                    
                  }
                }
              }
              .item-content {
                padding-left: 0.937rem;
              }

              .badge {
                margin-left: 2.5rem;
              }
              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .rtl & {
                i {
                  @extend .mr-0;
                  margin-left: 10px;
                }
                .badge {
                  margin-left: 0;
                  margin-right: 2.5rem;
                }
              }
            }

            .dropdown-divider {
              margin: 0;
            }
          }
          i {
            margin-right: .5rem;
            vertical-align: middle;
          }
          @media (max-width: 1089px) {
            position: static;
            .navbar-dropdown {
              left: 20px;
              right: 20px;
              top: $navbar-height;
              width: calc(100% - 40px);
            }
          }
          .indicator {
            position: relative;
            padding: 0;
            text-align: center;
            i {
              font-size: 1.9rem;
              color: #E64E19;
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 59%;
              width: 8px;
              height: 8px;
              border-radius: 100%;
              background: theme-color(danger);
              top: 9px;
            }
            &:after {
              display: none;
            }
          }
        }
      }
      &.navbar-nav-right {
        @extend .align-self-stretch;
        @extend .align-items-stretch;
        &ul li {
          line-height: .75rem;
        }
        .nav-item {
          @extend .d-flex;
          @extend .align-items-center;
          &.nav-settings {
            @extend .d-none;
            @extend .d-lg-flex;
          }
        }        
        @media (min-width: 1090px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
        @media (max-width: 560px) {
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: center;
        }
      }
    }
  }

}
@media (max-width:1089px) {
  .navbar {
    flex-direction: row;
    .navbar-brand-wrapper {
      width: 55px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {    
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
}

.admin-guide {
  position: relative;
  a {
    position: absolute;
    right: 20px;
    top: 120px;
    width: 3rem;
    height: 3rem;
    background: theme-color("light-dark");
    border-radius: 50%;
    border: 3px solid #fff;
    display: block;
    text-align: center;
    i {
      vertical-align: auto;
      font-size: 1.9rem;
      line-height: 2.7rem;
      text-shadow: 0 1px 3px #2e353e;
      color: #45ec62;
    }
  }
}